export class PlaylistManager {
  createPlaylistFromAudioUrl(audioUrl, title, description, image) {
    if (!audioUrl) {
      return null
    }

    const audio = {
      id: `audio_${audioUrl}`,
      index: 0,
      isMainAudio: true,
      hasAccess: true,
      logoUrl: image,
      logoUrlOverview: image,
      slug: '',
      title,
      type: description,
      descriptionLong: '',
      notes: '',
      repliesCount: 0,
      duration: 0,
      audioUrl,
      attachments: [],
      mentor: null,
      ...this.initializeAudioDefaults(),
      formattedDuration: '',
      listeningTimeStart: 0,
      listeningTimeEnd: 0,
    }

    return {
      id: `playlist_${audioUrl}`,
      isActive: false,
      type: 'single',
      currentIndex: 0,
      audios: [audio],
    }
  }

  createPlaylistFromCourse(course) {
    // return if course is null or undefined
    if (!course) { return }

    const playlistAudios = []

    if (course?.introduction_audio_url) { playlistAudios.push(this.createAudioObject(course, 'intro', true)) }

    course.lessons?.forEach((lesson) => {
      playlistAudios.push(this.createAudioObject(course, lesson, false))
    })

    return {
      id: `course_${course.id}`,
      isActive: false,
      type: 'course',
      course,
      currentIndex: 0,
      audios: playlistAudios,
    }
  }

  createAudioObject(course, lesson, isIntro) {
    const isCourseIntro = isIntro && lesson === 'intro'
    const baseInfo = isCourseIntro ? course : lesson

    return {
      id: isCourseIntro ? `intro_${course.id}` : lesson.id,
      index: isCourseIntro ? 0 : lesson.index,
      isMainAudio: isCourseIntro,
      hasAccess: isCourseIntro || lesson.audio_url !== undefined,
      logoUrl: course.image_urls?.medium,
      logoUrlOverview: course.overview_image_urls.medium,
      slug: baseInfo.slug,
      title: isCourseIntro ? 'Listen to Preview' : lesson.title,
      type: isCourseIntro ? 'intro' : lesson.type,
      descriptionLong: baseInfo.description_long || '',
      notes: baseInfo.notes || '',
      repliesCount: baseInfo.replies_count || 0,
      duration: baseInfo.duration || 0,
      audioUrl: isCourseIntro ? course.introduction_audio_url : lesson.audio_url,
      attachments: baseInfo.attachments || [],
      mentor: course.mentor,
      ...this.initializeAudioDefaults(),
      formattedDuration: isCourseIntro ? '' : this.formatDuration(lesson.duration),
      listeningTimeStart: lesson.progress?.current_position || 0,
      listeningTimeEnd: lesson.progress?.current_position || 0,
    }
  }

  initializeAudioDefaults() {
    return {
      audioElement: null,
      isLoading: false,
      isPlaying: false,
      progress: {},
      currentProgressInPercent: 0,
      currentTimeInSec: 0,
      timeRemainingInSec: 0,
      formattedTime: '00:00',
      formattedTimeRemaining: '00:00',
      listeningTime: 0,
    }
  }

  formatDuration(duration) {
    const minutes = Math.floor(duration / 60) || 0
    const seconds = (duration - minutes * 60) || 0

    return `${minutes}m ${(seconds < 10 ? '0' : '') + Math.round(seconds)}s`
  }
}
